import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useActionData, useSearchParams } from "@remix-run/react";
import { useState } from "react";
import { RiArrowRightLine, RiCheckDoubleLine } from "react-icons/ri";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { ValidatedForm, validationError } from "remix-validated-form";

import { ErrorComponent } from "~/components/error-boundary";
import { ForgottenPasswordModal } from "~/components/ForgottenPasswordModal";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { ValidatedCheckbox } from "~/components/ui/forms/checkbox";
import { ValidatedInput } from "~/components/ui/forms/input";
import { PageContainer } from "~/components/ui/layout/page-container";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { PageHeading } from "~/components/ui/page-heading";
import { Heading, Link, Text } from "~/components/ui/typography";
import { useToastError } from "~/hooks/use-toast-error";
import { login } from "~/models/session.server";
import { getCurrentUser } from "~/models/user.server";
import { checkCsrf } from "~/utils/csrf.server";
import { ToastError, UnauthorizedError } from "~/utils/errors";
import { handleError } from "~/utils/errors.server";
import { getSeoMeta } from "~/utils/seo";
import { loginValidator } from "~/validators/login";

export const meta: MetaFunction = (metaArgs) => {
  return getSeoMeta(metaArgs, {
    title: "Connectez vous à votre compte",
    description: "Connectez vous à votre compte pour accéder à votre espace client.",
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const user = await getCurrentUser({ request });

    if (user) {
      return redirect("/");
    }

    return json({});
  } catch (error) {
    return handleError(error, {});
  }
};

export const action = async ({ request }: ActionFunctionArgs) => {
  try {
    const formData = await request.formData();
    const _action = formData.get("_action");

    await checkCsrf({ formData, headers: request.headers });

    const response: {
      globalError: null | string;
      ok: boolean;
      fieldErrors: null | Record<string, string>;
      closeModal?: boolean;
    } = {
      globalError: null,
      ok: false,
      fieldErrors: null,
      closeModal: false,
    };

    if (typeof _action !== "string" || _action.length === 0) {
      response.globalError = "Aucune action a effectuer";
      return json(response);
    }

    const url = new URL(request.url);
    const redirectTo = url.searchParams.get("redirect") || "/";

    if (_action === "login") {
      const result = await loginValidator.validate(formData);

      if (result.error) {
        return validationError(result.error, result.submittedData);
      }

      return await login({ data: result.data, request, redirectTo }).catch((error) => {
        if (error instanceof UnauthorizedError && error.data) {
          const errorData = error.data as any;
          if (errorData.message.includes("Invalid credentials")) {
            throw new ToastError("Email ou mot de passe incorrect", { status: 401 });
          }
        }
        throw error;
      });
    }

    // if (_action === "activateAccount") {
    //   const result = await activateAccountValidator.validate(formData);

    //   if (result.error) {
    //     return validationError(result.error, result.submittedData);
    //   }

    //   const { email, recaptcha } = result.data;

    //   const responseData = await activateAccount({
    //     email,
    //     recaptchaToken: recaptcha,
    //     request,
    //   });

    //   return json({
    //     ...responseData,
    //     ok: true,
    //     closeActivateSection: true,
    //     successMessage:
    //       "Si votre adresse email est associée à un compte non activé, nous vous avons envoyé un email contenant un lien pour initialiser votre mot de passe. Veuillez nous contacter si vous ne recevez pas l'email.",
    //   });
    // }

    return json({ ok: false, globalError: "Aucune action a effectuer" });
  } catch (error) {
    return handleError(error, {});
  }
};

const LoginPage = () => {
  // const transition = useNavigation();
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const actionData = useActionData();
  useToastError(actionData);

  const [isForgottenPasswordModalOpen, setIsForgottenPasswordModalOpen] = useState(
    searchParams.has("forgotPassword") || false
  );

  // const [isActiveAccountSectionOpen, setIsActiveAccountSectionOpen] = useState(false);

  // const [activateAccountRecaptchaToken, setActivateAccountRecaptchaToken] = useState("");

  // const activateAccountFetcher = useFetcher<any>();
  // const [isActivateFormSent, setIsActivateFormSent] = useState(false);
  // const [isFetcherLoading, isFetcherDone] = useManagedFetcher({
  //   fetcher: activateAccountFetcher,
  // });

  // useEffect(() => {
  //   if (
  //     isActivateFormSent &&
  //     isFetcherDone &&
  //     activateAccountFetcher.data &&
  //     "closeActivateSection" in activateAccountFetcher.data &&
  //     activateAccountFetcher.data?.closeActivateSection
  //   ) {
  //     setIsActiveAccountSectionOpen(false);
  //     setIsActivateFormSent(false);
  //   }
  // }, [isFetcherDone, activateAccountFetcher, isActivateFormSent]);

  // useEffect(() => {
  //   if (isFetcherLoading) {
  //     setIsActivateFormSent(true);
  //   }
  // }, [isFetcherLoading]);

  return (
    <>
      <ForgottenPasswordModal
        isOpen={isForgottenPasswordModalOpen}
        onClose={() => setIsForgottenPasswordModalOpen(false)}
      />
      <PageContainer>
        <PageHeading withBackButton />
        <Wrapper className="grid max-w-screen-lg grid-cols-1 gap-10 md:grid-cols-2">
          <Card className="p-8 shadow-md">
            <Heading as="h1" size="2xl">
              Déjà client ? Connectez-vous
            </Heading>
            <ValidatedForm validator={loginValidator} method="POST">
              <AuthenticityTokenInput />
              <input type="hidden" name="_action" value="login" />
              <ValidatedInput
                type="email"
                id="username"
                name="username"
                label="Email"
                placeholder="Entrez votre email"
                autoComplete="email"
                className="mt-8"
              />
              <ValidatedInput
                type="password"
                id="password"
                name="password"
                label="Mot de passe"
                placeholder="Entrez votre mot de passe"
                autoComplete="current-password"
                className="mt-4"
              />
              <Button
                type="button"
                onClick={() => setIsForgottenPasswordModalOpen(true)}
                variant="link"
                className="px--0 mt-4 h-auto pl-4 font-normal"
              >
                Mot de passe oublié ?
              </Button>
              <ValidatedCheckbox
                id="stayConnected"
                name="stayConnected"
                defaultChecked={true}
                label="Rester connecté"
                className="mt-8"
              />
              <Button
                type="submit"
                icon={<RiArrowRightLine />}
                iconPosition="right"
                className="mt-4"
              >
                Me connecter
              </Button>
            </ValidatedForm>

            <Text size="sm" muted className="mt-4">
              Des difficultés pour vous connecter ? <Link to="/nous-contacter">Contactez-nous</Link>
            </Text>
          </Card>

          <Card className="p-8 shadow-md">
            <Heading as="h2" size="2xl">
              Ou demandez votre accès pro :
            </Heading>
            <Text size="sm" className="mt-2">
              Ce que nous réservons à tous les héros qui nous rejoignent :
            </Text>
            <ul className="mt-8 space-y-3">
              <li className="flex h-8 max-w-max items-center gap-3 rounded-full border border-primary-500/5 bg-primary-100/60 px-6 text-primary-800">
                <RiCheckDoubleLine className="size-5 text-primary-500" />
                <Text size="sm">Une réelle autonomie</Text>
              </li>
              <li className="flex h-8 max-w-max items-center gap-3 rounded-full border border-primary-500/5 bg-primary-100/60 px-6 text-primary-800">
                <RiCheckDoubleLine className="size-5 text-primary-500" />
                <Text size="sm">L’accès à vos prix personnalisés</Text>
              </li>
              <li className="flex h-8 max-w-max items-center gap-3 rounded-full border border-primary-500/5 bg-primary-100/60 px-6 text-primary-800">
                <RiCheckDoubleLine className="size-5 text-primary-500" />
                <Text size="sm">Notre stock en direct</Text>
              </li>
              <li className="flex h-8 max-w-max items-center gap-3 rounded-full border border-primary-500/5 bg-primary-100/60 px-6 text-primary-800">
                <RiCheckDoubleLine className="size-5 text-primary-500" />
                <Text size="sm">Un confort d&apos;achat pour vous</Text>
              </li>
              <li className="flex h-8 max-w-max items-center gap-3 rounded-full border border-primary-500/5 bg-primary-100/60 px-6 text-primary-800">
                <RiCheckDoubleLine className="size-5 text-primary-500" />
                <Text size="sm">Un accès à vos documents</Text>
              </li>
            </ul>
            <Text size="sm" muted className="mt-8">
              Réservé aux professionnels
            </Text>
            <Button
              as="link"
              to="/inscription"
              variant="primary"
              icon={<RiArrowRightLine />}
              iconPosition="right"
              className="mt-2"
            >
              Créer mon accès pro
            </Button>
          </Card>
        </Wrapper>
      </PageContainer>
    </>
  );
};

export default LoginPage;

export const ErrorBoundary = () => {
  return <ErrorComponent />;
};
